<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>해당 스페이스로 재생하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="linkMySpace()">아니오</p>
                <p id="color-text" @click="linkPlayer()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import router from '@/router';
import { leaveChannel } from '@/assets/js/webcast';

export default defineComponent({
  name: 'PaymentFinished',
  props: {
    spaceId: String,
    spaceNm: String,
    findChannel: String
  },
  methods: {
    async linkPlayer () {
      // channelId가 있을때
      if (this.$store.getters.getIsAppOpen) {
        this.$emit('closePlay');
        window.location.href = `aplayz://play?spaceId=${this.spaceId}`;
      } else {
        if (this.$store.state.channelId !== '') await leaveChannel();
        if (this.$store.state.isBrandMusic) {
          // 브랜드 음원 값이 true인 경우
          this.$store.commit('setIsBrandMusicPlay', false); // 브랜드 음원의 재생 값 false
          this.$store.commit('setIsBrandMusic', false); // 브랜드 음원 값 false
          this.$VideoPlayer.onPause();
        }
        VueCookies.set('spaceId', this.spaceId);
        this.$store.commit('setSpaceNm', this.spaceNm);
        this.$store.commit('setSpaceId', null);
        this.$store.commit('setMute', false);
        this.$store.commit('setFindChannel', this.findChannel);
        this.$VideoPlayer.onPause();
        this.$emit('closePlay');

        router.push({ name: 'PlayerView' }).catch(() => {});
      }
    },
    linkMySpace () {
      this.$emit('closePlay');
      router.push({ name: 'MySpace' });
    }
  }
});
</script>
