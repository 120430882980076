<template>
    <section class="pay_complete">
        <!-- <Floating :currentMusic="this.$store.state.musicList[this.$store.state.currentMusicIdx]" :isPlay="this.$store.state.isPlay === '' ? false : this.$store.state.isPlay" /> -->
        <Play :spaceId="spaceId" :spaceNm="spaceNm" :findChannel="$route.params.findChannel" v-if="ShowPlay" @closePlay="ShowPlay = false" />
        <div class="pay_complete_box pay_box pay_btn">
            <img src="/media/img/join_1_icon.png" alt="">
            <h1>결제완료</h1>
            <p>이제 APLAYZ를 이용할 모든 준비가 끝났습니다.<br>지금 바로 PLAY해보세요!</p>
            <a><button @click="ShowPlay = true">확인</button></a>
        </div>
    </section>
</template>

<script>
import Play from "@/components/modal/Space/PaymentFinished.vue";
// import Floating from "@/components/Floating.vue";

export default {
  data () {
    return {
      ShowPlay: false,
      spaceId: localStorage.getItem("spaceId")
    };
  },
  props: {
    spaceNm: String
  },
  components: {
    Play
    // Floating
  }

};
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
